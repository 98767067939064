import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { Overlay } from "@nulogy/components";
import { config } from "../../playgrounds/overlay";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Overlay`}</h1>
    <p>{`Overlay separates foreground from the background content and overlays the whole screen.`}</p>
    <Playground {...config} containWithin compilerHeight="200px" mdxType="Playground" />
    <h2>{`Props`}</h2>
    <p>{`As an extension of `}<a parentName="p" {...{
        "href": "/flex"
      }}>{`Flex`}</a>{`, Overlay accepts all of Flex's props in addition to the props listed below.`}</p>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/modal"
        }}>{`Modal`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/loading-animation"
        }}>{`LoadingAnimation`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      